<template>
  <div id="map" :class="{'map-fullScreen':!isFullScreen}"></div>
</template>

<script>
import "@/assets/js/infoBox.js"
import coordtransform from "coordtransform"

export default {
  data() {
    return {
      isFullScreen: false,
      map: null,
      infoWindows: [],
      mapTimer: null,
      timerIndex: 0
    }
  },
  computed: {
    parkingLotList() {
      return this.$store.state.bigScreen.parkingLotList
    }
  },
  watch: {
    parkingLotList: {
      handler(list) {
        if (list.length) {
          for (let item of list) {
            if (!this.isCheckInfoWindows(item.parkingLotId)) {
              const point =coordtransform.gcj02tobd09(item.parkingLotLng, item.parkingLotLat)
              this.createMarker(item, new BMapGL.Point(point[0], point[1]))
            }
          }
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.initMap()
      this.mapAnimation()
    })
  },
  methods: {
    initMap() {
      this.map = new BMapGL.Map("map");          // 创建地图实例
      this.map.centerAndZoom("宁波市", 14);
      this.map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      this.map.setMapStyleV2({
        styleId: 'a85c5a62023126ce6d4dffdfeb1ec450'
      });
    },
    mapAnimation() {
      clearInterval(this.mapTimer)
      this.mapTimer = setInterval(() => {
        if (this.infoWindows.length) {
          this.markerHandleClick(this.infoWindows[this.timerIndex].parkingInfo.parkingLotId)
          if (++this.timerIndex >= this.infoWindows.length) {
            this.timerIndex = 0
          }
        }
      }, 5000)
    },
    createMarker(item, point) {
      const myIcon = new BMapGL.Icon(require('@/assets/images/bigScreen/map-icon.png'), new BMapGL.Size(26, 40));
      // 创建标注对象并添加到地图
      let marker = new BMapGL.Marker(point, {icon: myIcon});
      marker.parkingInfo = item
      this.map.addOverlay(marker);
      marker.addEventListener("click", (e) => {
        this.markerHandleClick(e.target.parkingInfo.parkingLotId)
      })
      this.createInfoBox(item, point)
    },
    markerHandleClick(parkingLotId) {
      if (this.mapTimer) {
        clearInterval(this.mapTimer)
        setTimeout(() => {
          this.mapAnimation()
        }, 5000)
      }
      for (let item of this.infoWindows) {
        if (parkingLotId === item.parkingInfo.parkingLotId) {
          const coord = coordtransform.gcj02tobd09(item.parkingInfo.parkingLotLng, item.parkingInfo.parkingLotLat)
          let point = new BMapGL.Point(coord[0], coord[1])
          item.open(point);
          this.map.setCenter(point, 12)
        } else {
          item.close();
        }
      }
    },
    createInfoBox(item) {
      let transfer = item.parkingLotName === "创意三厂" ? "宁波市肛肠医院" : '创意三厂'
      let w = document.documentElement.clientWidth / 1920 * 16
      const infoEl = item.class==='redProportion' ? `
        <div class="infoEl">
            <p>
                <span>停车场名称</span>
                <span>${item.parkingLotName}</span>
            </p>
            <p>
                <span>停车场地址</span>
                <span>${item.parkingLotAddress}</span>
            </p>
            <p>
                <span>剩余车位</span>
                <span class="redTip">${item.idleLots} 个<br />
                    <i class="scrollAnimation">建议转至${transfer}</i>
                </span>
            </p>
        </div>
      ` : `
        <div class="infoEl">
            <p>
                <span>停车场名称</span>
                <span>${item.parkingLotName}</span>
            </p>
            <p>
                <span>停车场地址</span>
                <span>${item.parkingLotAddress}</span>
            </p>
            <p>
                <span>剩余车位</span>
                <span>${item.idleLots} 个</span>
            </p>
        </div>
      `
      const infoBox = new BMapGLLib.InfoBox(this.map, infoEl, {
        offset: new BMapGL.Size(12 * w, -3.2 * w),
        closeIconUrl: require("../../assets/images/bigScreen/close.png"),  //关闭信息窗口icon
        closeIconMargin: "1rem 1rem 0 0", //关闭按钮的位置
        enableAutoPan: true,
      });
      infoBox.parkingInfo = item
      this.infoWindows.push(infoBox)

    },
    isCheckInfoWindows(parkingLotId) {
      for (let item of this.infoWindows) {
        if (item.parkingLotId === parkingLotId) {
          return true
        }
      }
      return false
    }
  },
  destroyed() {
    clearInterval(this.mapTimer)
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100%;
  position: absolute;

  .BMap_cpyCtrl {
    display: none;
  }

  .anchorBL {
    display: none;
  }

  &.map-fullScreen {
    width: 100vw;
    height: 100vh;
  }

  .infoBox {
    width: 21.75rem;
    height: 12.06rem;
    background: url("../../assets/images/bigScreen/map-label-bg.png") no-repeat center center;
    background-size: contain;

    & > img {
      width: 1.5rem;
      object-fit: contain;
    }

    .infoEl {
      width: 90%;
      padding-top: 1.4rem;
      margin: 0 auto;

      p {
        width: 100%;
        color: #2AE2FD;
        margin-bottom: 1.38rem;
        display: flex;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-size: 1rem;
          display: inline-block;
          width: 55%;

          &:first-child {
            margin-right: 1.19rem;
            text-align: right;
            color: white;
            width: 40%;
          }
        }
      }

      .redTip {
        color: red;
        overflow: hidden;

        .scrollAnimation {
          display: inline-block;
          animation: scroll 5s linear infinite;
        }
      }


      @keyframes scroll {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    }
  }
}
</style>
